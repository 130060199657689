<template>
  <b-card class="m-3" style="height: 440px; overflow-y: auto">
    <div class="d-flex justify-content-between">
      <div style="font-size: 24px; font-weight: 900; font-family: sans-serif">
        #{{ data.tribe_id.name }}
      </div>
    </div>
    <traveller-section :data="travellerDetails" />
    <payment-section :data="paymentDetails" />
    <details-section :data="bookingDetails" />
    <div
      class="mt-3"
      v-if="
        data.booking_approval_status == 1 || data.booking_approval_status == 0
      "
      style="display: flex; flex-wrap: wrap; justify-content: space-between"
    >
      <b-button @click="rejectBooking(data._id)" class="reject-btn">{{
        rejecting ? "Rejecting..." : "Reject"
      }}</b-button>
      <b-button class="approve-btn" @click="approveBooking(data._id)">{{
        approving ? "Approving..." : "Approve"
      }}</b-button>
    </div>
  </b-card>
</template>

<script>
import { booking } from "@/config/api/booking";
import PaymentSection from "../book_card_details/PaymentSection.vue";
import TravellerSection from "../book_card_details/TravellerSection.vue";
import DetailsSection from "../book_card_details/DetailsSection.vue";
import bookingStatus from "@/data/bookingStatus";

export default {
  props: ["data", "userType", "status"],
  data() {
    return {
      approving: false,
      rejecting: false,
      initiatingSecondPayment: false,
      bookingStatus: bookingStatus,
    };
  },
  computed: {
    travellerDetails() {
      return {
        id: this.data._id,
        travellers: this.data.travellers,
        travellersCount: this.data.number_of_travellers,
        status: this.data.booking_approval_status,
      };
    },
    paymentDetails() {
      return {
        id: this.data.id,
        first_payment: this.data.first_payment_reference.status || null,
        second_payment: this.data.second_payment_reference.status || null,
        booking_payment_status: this.data.booking_payment_status || null,
        amount: this.data.price,
        payment_status: this.data.booking_approval_status,
        payment_method:
          this.data.transactions?.find(
            (transaction) => transaction.status === "SUCCESS"
          )?.payment_method || "Not provided",
      };
    },
    bookingDetails() {
      return {
        program_name: this.data.program_id?.name || "Not provided",
        booking_date: this.data.booking_date,
        booked_by: {
          name: this.data.user_id?.fullname || "Not provided",
          email: this.data.user_id?.email || "Not provided",
        },
        reserved_on: this.data.createdAt,
        vendor: {
          company_name: this.data.vendor_id?.company_name || "Not provided",
          email: this.data.vendor_id?.email || "Not provided",
          contact_number: this.data.vendor_id?.contact_number || "Not provided",
        },
      };
    },
  },
  components: {
    PaymentSection,
    TravellerSection,
    DetailsSection,
  },
  methods: {
    openModal(id) {
      this.$bvModal.show(`traveller${id}`);
    },
    approveBooking(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, do you want to approve the booking?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = booking.approveBooking;
            api.data = {
              type: this.userType,
              bookingId: id,
            };
            this.approving = true;

            this.generateAPI(api)
              .then(() => {
                setTimeout(() => {
                  this.$bvToast.toast("Booking Approved", {
                    title: "Booking Approvel",
                    variant: "success",
                    solid: true,
                  });
                }, 1000);

                this.$emit("loadData");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Booking approvel failed with the error ${err.response.data}`,
                  {
                    title: "Booking Approvel",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.approving = false;
              });
          }
        });
    },
    rejectBooking(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, do you want to reject the booking?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = booking.rejectBooking;
            api.data = {
              type: this.userType,
              bookingId: id,
            };
            this.rejecting = true;
            this.generateAPI(api)
              .then(() => {
                setTimeout(() => {
                  this.$bvToast.toast("Booking Rejected successfully", {
                    title: "Booking Rejection",
                    variant: "success",
                    solid: true,
                  });
                }, 1000);
                this.$emit("loadData");
              })

              .catch((err) => {
                this.$bvToast.toast(
                  `Booking rejection failed with the error ${err.response.data}`,
                  {
                    title: "Booking Rejection",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.rejecting = false;
              });
          }
        });
    },
    initateSecondPayment(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure, do you want to initiate second payment?",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const api = booking.initSecondPayment;
            api.params = {
              booking_id: id,
            };
            this.initiatingSecondPayment = true;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast(
                  "Booking second payment initiation success.",
                  {
                    title: "Booking Second Payment",
                    variant: "success",
                    solid: true,
                  }
                );
                this.$emit("loadData");
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Booking second payment initiation failed with the error ${err.response.data.message}`,
                  {
                    title: "Booking Second Payment",
                    variant: "danger",
                    solid: true,
                  }
                );
              })
              .finally(() => {
                this.initiatingSecondPayment = false;
              });
          }
        });
    },
  },
};
</script>

<style>
.view-traveller {
  border: none;
  background-color: gray;
  color: #fff;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 12px;
}

.reject-btn {
  background-color: #ff3d60;
}

.approve-btn {
  background-color: #1cbb8c;
}

.reject-btn,
.approve-btn {
  color: white !important;
  border: none;
}

.reject-btn:hover,
.approve-btn:hover {
  border: none;
}
</style>
