<template>
  <div>
    <Layout>
      <CountryTable></CountryTable>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import CountryTable from "./country-table.vue";

export default {
  components: { Layout, CountryTable },
  data() {
    return {};
  },
};
</script>

<style></style>
