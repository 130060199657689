<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="right-align m-2">
        <b-button variant="primary" @click="modalShow = !modalShow">
          <i class="ri-coupon-line"></i><span class="pl-2">Add Country</span>
        </b-button>
      </div>
    </div>

    <b-table
      striped
      hover
      :fields="fields"
      :busy="isLoading"
      :responsive="true"
      :items="items"
    >
      <template #cell(name)="data">
        <span>
          {{ data.item.name !== null ? data.item.name : "" }}
        </span>
      </template>
      <template #cell(name_ar)="data">
        <span>
          {{ data.item.name_ar !== null ? data.item.name_ar : "-" }}
        </span>
      </template>
      <template #cell(flag)="data">
        <img
          v-if="data.item.flag"
          :src="data.item.flag"
          alt="Image"
          style="width: 50px; height: auto"
        />
      </template>

      <!-- <template #cell(value)="data">
        <span>
          {{ data.item.value !== null ? data.item.value : "" }}
        </span>
      </template> -->
      <template #cell(createdAt)="data">
        <span>
          {{
            data.item.createdAt !== null
              ? moment(data.item.createdAt).format("LL")
              : ""
          }}
        </span>
      </template>
      <template #cell(updatedAt)="data">
        <span>
          {{
            data.item.updatedAt !== null
              ? moment(data.item.updatedAt).format("LL")
              : ""
          }}
        </span>
      </template>

      <template #cell(actions)="row">
        <b-button @click="handlEdit(row.item)"> Edit </b-button>
        <b-button @click="handleDelete(row.item)"> Delete </b-button>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner type="grow" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    >
    </b-pagination>
    <b-modal
      id="priority-modal"
      v-model="modalShow"
      hide-footer
      @hide="closeModal"
    >
      <FormulateForm
        @submit="handleSubmit"
        class="mb-4"
        ref="couponForm"
        v-model="form"
      >
        <img
          class="w-10 rounded"
          width="400"
          v-if="this.flag"
          :src="this.flag"
          alt="Cover image"
        />
        <FormulateInput
          name="name"
          type="text"
          validation="required"
          label="Country Name*"
        />
        <FormulateInput name="name_ar" type="text" label="Country Name(AR)" />

        <!-- <FormulateInput
          v-model="show_on_app"
          name="show_on_app"
          type="select"
          label="Show On App"
          :options="showOnAppOptionsPopUp"
          placeholder="Select an option"
        /> -->
        <!-- <FormulateInput
          type="image"
          label="Image"
          name="image"
          v-model="images"
        /> -->
        <!-- <FormulateInput
          type="file"
          name="image"
          label="Select image"
          help="Select  image to upload"
          validation="required|mime:image/jpeg,image/png,image/gif,image/svg+xml"
          accept="image/*"
        />
 -->
        <FormulateInput
          type="image"
          label="Flag"
          name="flag"
          v-model="images"
        />
        <b-button variant="primary" type="submit" class="m-auto"
          >Submit</b-button
        >
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { country } from "@/config/api/country";

export default {
  mounted() {
    this.getReports();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getReports();
    },
  },
  data() {
    return {
      items: [],
      dateRange: null,
      flag: "",
      images: null,
      currentPage: 1,
      rows: 2,
      perPage: 5,

      fields: [
        { key: "flag", label: "Flag" },
        { key: "name", label: "Name" },
        { key: "name_ar", label: "Name(AR)" },
        // { key: "show_on_app", label: "Show on app" },

        { key: "createdAt", label: "Created At" },
        { key: "updatedAt", label: "Updated At" },
        { key: "actions", label: "Actions" },
      ],
      isLoading: false,
      modalShow: false,

      form: {
        name_ar: "",
        name: "",
      },
      // name_ar: "",

      // image: "",
      // show_on_app: false,
      dataLoading: false,

      editModal: false,
      selectedId: null,
    };
  },

  methods: {
    closeModal() {
      this.name = "";
      this.name_ar = "";
      // this.image = "";
      this.flag = null;
      this.images = null;
      this.$bvModal.hide("priority-modal");
      this.$refs.couponForm.reset();
    },

    async getReports() {
      this.isLoading = true;
      this.tableData = [];
      const api = country.getAll;
      api.params = {
        page: this.currentPage,
        limit: this.perPage,
        // locale: this.selectedLocale,
        // show_on_app: this.selectedOnApp,
      };
      await this.generateAPI(api)
        .then((res) => {
          this.items = res.data.data;
          this.rows = res.data.totalCount;
          this.perPage = 5;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },

    async handleSubmit(data) {
      const formData = new FormData();
      if (data.name) formData.append("name", data.name);
      if (data.name_ar) formData.append("name_ar", data.name_ar);
      if (this.images) {
        formData.append("file", this.images?.files[0]?.file);
      }
      console.log(
        data.name,
        data.name_ar,
        this.images?.files[0]?.file,
        this.selectedId,
        formData.get("name"),
        "this.images?.files[0]?.file"
      );
      // const body = {
      //   name: data.name,
      //   name_ar: data.name_ar,
      //   file: data.flag,
      //   // show_on_app: data?.show_on_app,
      // };
      let api;
      if (this.editModal) {
        const id = this.selectedId;
        api = country.editCountry;
        api.id = id;
        // body.id = this.selectedId;
        // formData.append("id", this.selectedId);
        // const id = String(this.selectedId);
        // api = `${country.editCountry}${id}`;
      } else {
        api = country.createCountry;
      }

      api.data = formData;
      this.dataLoading = true;
      await this.generateAPI(api)
        .then((res) => {
          if (res) {
            this.$bvToast.toast(res?.data?.message, {
              title: "Country",
              variant: "success", // Use 'danger' for error toasts
              solid: true,
            });
            this.getReports();
            this.modalShow = false;
            this.closeModal();
          }
        })
        .catch((err) => {
          this.$bvToast.toast(err?.response?.data?.message, {
            title: "Country",
            variant: "danger", // Use 'danger' for error toasts
            solid: true,
          });
        })
        .finally(() => {
          this.dataLoading = false;
          this.editModal = false;
          this.selectedId = null;
        });
    },
    handlEdit(row) {
      this.form.name = row?.name;
      this.form.name_ar = row?.name_ar;
      this.flag = row?.flag;
      // this.image = row?.image;

      // this.show_on_app = row?.show_on_app;
      this.modalShow = true;
      this.editModal = true;
      this.selectedId = row?._id;
    },
    handleDelete(item) {
      const api = country.deleteCountry;
      api.id = item._id;
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the country", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.getReports();
            });
          }
        });
    },
  },
};
</script>

<style></style>
