<template>
  <Layout>
    <div class="right-align m-2">
      <b-button variant="primary" @click="addTribe">
        <i class="fas fa-user-plus"></i><span class="pl-2">Tribe</span>
      </b-button>
    </div>

    <div style="text-align: center; margin: 28px" v-if="dataLoading">
      <pulse-loader color="#e83e8c" />
    </div>
    <div class="tribe-title" style="margin-bottom: 10px">
      Total Docs : {{ rows }}
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div
            class="col-md-4 col-xl-3"
            v-for="(item, index) in tableData"
            :key="index"
          >
            <div
              class="card tribe-details-main-img"
              :style="`background:url(${item.cover_image})`"
            >
              <div
                class="card-body"
                style="background-color: rgba(0, 0, 0, 0.5)"
              >
                <b-dropdown
                  right
                  toggle-class="arrow-none card-drop"
                  class="float-right"
                  variant="white"
                  style="margin-right: -22px; margin-top: -8px"
                >
                  <template v-slot:button-content>
                    <i class="ri-settings-5-line"></i>
                  </template>
                  <b-dropdown-item @click="viewTribeDetails(item._id)"
                    >Details</b-dropdown-item
                  >
                  <b-dropdown-item @click="editTribeDetails(item._id)"
                    >Edit</b-dropdown-item
                  >
                  <b-dropdown-item @click="deleteTribe(item)">
                    Delete</b-dropdown-item
                  >
                </b-dropdown>
                <div style="margin-top: 48px">
                  <div class="tribe-title">{{ item.name }}</div>
                  <div class="tribe-subtitle">{{ item.title }}</div>
                  <div
                    class="d-flex justify-content-end mt-1"
                    style="color: white"
                  >
                    <span>
                      <ul v-if="item.regionDet && item.regionDet.length > 0">
                        <li v-for="region in item.regionDet" :key="region._id">
                          {{ region.name }}
                        </li>
                      </ul>
                      <p v-else>Not provided!</p>
                      <span class="nomad-primary-color"
                        ><i class="ri-map-pin-line"></i
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
      </div>
    </div>

    <div class="row" v-if="!dataLoading">
      <div class="col-12">
        <div class="float-right">
          <ul class="pagination pagination-rounded mb-4">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="pageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import appConfig from "@/app.config";
import { tribe } from "@/config/api/tribes";
import { findLabel } from "@/data/tribes";
export default {
  page: {
    title: "Normal users",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      selectedIndex: 0,
      findLabel: findLabel,
      isActive: false,
      tableData: [],
      title: "",
      items: [
        {
          text: "Users",
          href: "/",
        },
        {
          text: "Normal",
          active: true,
        },
      ],
      rows: 0,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      dataLoading: false,
    };
  },
  computed: {},
  mounted() {},
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getTribeAllDetails();
    },
  },
  created() {
    this.getTribeAllDetails();
  },

  methods: {
    getTribeAllDetails() {
      this.dataLoading = true;
      this.tableData = [];
      const api = tribe.getAllTribes;
      api.params = { page: this.currentPage, perPage: this.perPage };
      this.generateAPI(api)
        .then((res) => {
          this.tableData = res.data.data.docs;

          this.rows = res.data.data.totalDocs;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log | "Loaded";
        });
    },
    addTribe() {
      this.$router.push({ name: "addTribe" });
    },
    viewTribeDetails(id) {
      this.$router.push({ name: "viewTribe", query: { id: id } });
    },
    editTribeDetails(id) {
      this.$router.push({ name: "editTribe", query: { id: id } });
    },
    deleteTribe(data) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete the tribe ${data.name}.`,
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const api = tribe.deleteTribe;
            api.params = { tribeId: data._id };
            this.generateAPI(api).then((res) => {
              console.log(res.data);
              this.getTribeAllDetails();
            });
          }
        })
        .catch(() => {
          console.log("Tribe delete confirm error");
        });
    },
  },
};
</script>

<style scoped>
.tribe-title {
  color: #fa7da9;
  font-weight: 900;
  font-size: 1.1rem;
  padding-top: 8px;
}
.tribe-subtitle {
  color: #fff;
}
@media screen and (min-width: 820px) {
  .card img {
    height: 142px;
  }
}
.tribe-details-main-img {
  height: 168px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>
