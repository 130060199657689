import permissions from "../router/permissions";
import support_routes from "../config/data/support";
export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/",
    permission: permissions.home,
  },
  {
    id: 2,
    label: "Users",
    icon: "ri-user-line",
    permission: permissions.normalUser,
    subItems: [
      {
        id: 200,
        label: "Normal",
        link: "/users/normal",
        permission: permissions.normalUser,
      },
      {
        id: 201,
        label: "Vendor",
        link: "/users/vendor",
        permission: permissions.vendorUser,
      },
      {
        id: 202,
        label: "Guide",
        link: "/users/guide",
        permission: permissions.programGuide,
      },
    ],
  },
  {
    id: 3,
    label: "Tribes",
    icon: "ri-ancient-gate-line",
    link: "/tribes",
    permission: permissions.viewTribe,
  },
  {
    id: 4,
    label: "Bookings",
    icon: "ri-calendar-check-line",
    link: "/bookings",
    permission: permissions.viewBookings,
  },

  // Vendor route start from here
  {
    id: 6,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/vendor/dashboard",
    permission: permissions.vendorDashboard,
  },

  {
    id: 5,
    label: "Programs",
    icon: "ri-file-list-line",
    link: "/programs",
    permission: permissions.viewPrograms,
  },

  {
    id: 6,
    label: "Reports",
    icon: "ri-file-paper-fill",
    link: "/reports",
    permission: permissions.viewPrograms,
  },

  {
    id: 7,
    label: "Bookings",
    icon: "ri-calendar-check-line",
    link: "/vendor/booking",
    permission: permissions.vendorBooking,
  },
  {
    id: 8,
    label: "Settings",
    icon: "ri-settings-3-line",
    link: "/vendor/settings",
    permission: permissions.vendorSettings,
  },
  {
    id: 9,
    label: "Support",
    icon: "ri-user-line",
    permission: permissions.support,
    subItems: support_routes.map((item, index) => {
      return {
        id: index,
        label: item.label,
        link: `/support/${item.value}`,
        permission: permissions.support,
      };
    }),
  },
  {
    id: 10,
    label: "Moderation",
    icon: "ri-shield-user-line",
    permission: permissions.moderation,
    subItems: [
      {
        id: 300,
        label: "User",
        link: "/moderation/user",
        permission: permissions.moderation,
      },
      {
        id: 301,
        label: "Post",
        link: "/moderation/post",
        permission: permissions.moderation,
      },
    ],
  },
  {
    id: 11,
    label: "Discover",
    icon: "ri-file-text-line",
    link: "/discover",
    permission: permissions.viewPrograms,
  },
  // {
  //   id: 12,
  //   label: "Experience",
  //   icon: "ri-ancient-gate-line",
  //   link: "/experience",
  //   permission: permissions.moderation,
  // },

  {
    label: "Challenges",
    icon: "ri-task-line",
    link: "/challenges",
    permission: permissions.challenges,
  },
  {
    label: "Coupon",
    icon: "ri-coupon-line",
    link: "/coupon",
    permission: permissions.coupon,
  },
  {
    label: "Region",
    icon: "ri-compass-3-line",
    link: "/region",
    permission: permissions.region,
  },
  {
    label: "Country",
    icon: "ri-global-line",
    link: "/country",
    permission: permissions.country,
  },
  {

    label: "Climate",
    icon: "ri-temp-hot-fill",
    link: "/climate",
    permission: permissions.coupon,
  },

];
