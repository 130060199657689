export default {
  home: ["admin"],
  vendorDashboard: ["vendor"],
  normalUser: ["admin"],
  vendorSettings: ["vendor"],
  NormalUsersdetails: ["admin"],
  NormalUserOverview: ["admin"],
  NormalUserFollowers: ["admin"],
  NormalUserFollowing: ["admin"],
  NormalUserLikes: ["admin"],
  NormalUserPosts: ["admin"],
  vendorUser: ["admin"],
  programGuide: ["admin"],
  viewTribes: ["admin"],
  addTribe: ["admin"],
  viewTribe: ["admin"],
  editTribe: ["admin"],
  viewBookings: ["admin"],
  viewPrograms: ["admin", "vendor"],
  viewProgramDetails: ["admin", "vendor"],
  addProgram: ["admin", "vendor"],
  editProgram: ["admin", "vendor"],
  approveProgram: ["admin"],
  vendorBooking: ["vendor"],
  support: ["admin"],
  moderation: ["admin"],
  challenges: ["admin"],
  editChallenge: ["admin"],
  coupon: ["admin"],
  region: ["admin"],
  climate: ["admin"],
  country: ["admin"],
};
