export const country = {
    getAll: {
        url: "v2/admin/country/list",
        method: "GET",
        params: null,
    },

    createCountry: {
        url: "v2/admin/country/create",
        method: "POST",
        data: null,
    },
    editCountry: {
        url: "v2/admin/country/update/",
        method: "PUTFORM",
        data: null,
        id: null,
    },
    deleteCountry: {
        url: "v2/admin/country/delete/",
        method: "DELETE",
        id: null,
    }

}