<template>
  <b-modal id="date-gen" :footer="false">
    <div class="row">
      <div class="col-6">
        <FormulateInput type="date" v-model="start_date" label="Start date" />
      </div>
      <div class="col-6">
        <FormulateInput type="date" label="End date" v-model="end_date" />
      </div>
    </div>
    <div id="date-gen-error">
      Please choose a valid date ( start date must be earlier than end date and
      start date and end date must be a date after today )!
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="primary" @click="genrateDate(1)">
        <span v-if="generating"> Generating... </span>
        <span v-else>Generate </span>
      </b-button>
      <b-button size="sm" variant="danger" @click="genrateDate(0)">
        <span v-if="generating"> Removing... </span>
        <span v-else>Remove </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
export default {
  data() {
    return {
      start_date: null,
      end_date: null,
      generating: false,
    };
  },
  methods: {
    genrateDate(cond) {
      // Hide the error message initially
      document.getElementById("date-gen-error").style.visibility = "hidden";

      // Get today's date without the time portion for comparison
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (this.start_date && this.end_date) {
        // Convert dates to Date objects for comparison
        const startDate = new Date(this.start_date);
        const endDate = new Date(this.end_date);

        // Check if both dates are after today
        if (startDate > today && endDate > today && startDate < endDate) {
          this.genrating = true;
          const dates = this.getDates(this.start_date, this.end_date);

          this.genrating = false;
          cond === 1
            ? this.$emit("addDateGenerated", dates)
            : this.$emit("removeDates", dates);
          return;
        }
      }

      // Show the error message if validation fails
      document.getElementById("date-gen-error").style.visibility = "visible";
    },

    getDates(startDate, stopDate) {
      var dateArray = new Array();
      var currentDate = new Date(startDate);
      while (currentDate <= new Date(stopDate)) {
        dateArray.push(this.moment(new Date(currentDate)).format("YYYY-MM-DD"));
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    },
  },
};
</script>

<style>
#date-gen-error {
  text-align: center;
  color: #b92929;
  margin-top: 12px;
  visibility: hidden;
}
</style>
