<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <form-wizard
          class="tribe-form"
          color="#556ee6"
          finishButtonText="Submit"
          @on-complete="submitTribeForm"
          @on-change="tabChanged"
          style="max-width: 940px; margin: auto"
        >
          <tab-content
            icon="mdi mdi-account-edit-outline"
            :before-change="validateStep1"
          >
            <FormulateForm
              ref="firstStep"
              name="firstStep"
              v-model="values"
              :schema="step1"
              class="mb-4"
            />

            <label>Please pick location from map</label>
            <GmapMap
              :center="center"
              :zoom="2"
              style="height: 240px"
              @click="handleMapClick"
            >
              <GmapMarker
                :position="marker.position"
                :clickable="true"
                :draggable="true"
                @drag="handleMarkerDrag"
                @click="panToMarker"
              />
            </GmapMap>
            <div class="row">
              <div class="col-sm-6 col-12">
                <FormulateInput
                  type="number"
                  name="longitude"
                  v-model="marker.position.lng"
                  label="Longitude"
                  validation="required"
                />
              </div>
              <div class="col-sm-6 col-12">
                <FormulateInput
                  type="number"
                  name="latitude"
                  v-model="marker.position.lat"
                  label="Latitude"
                  validation="required"
                />
              </div>
            </div>
          </tab-content>
          <tab-content
            icon="mdi mdi-account-details-outline"
            :before-change="validateStep2"
          >
            <FormulateForm
              v-model="values"
              :schema="step2"
              ref="SecondStep"
              name="SecondStep"
            />
            <FormulateForm
              v-model="values"
              class="mt-4"
              :schema="transport"
              ref="ThirdStep"
              name="ThirdStep"
            />
            <FormulateForm
              ref="FourthStep"
              name="FourthStep"
              v-model="values"
              class="mt-4 mb-4"
              :schema="images"
              @submit="uploadImageData"
            />
          </tab-content>
          <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
            <div class="row">
              <div class="col-12">
                <div class="text-center">
                  <h6 style="margin-top: 42px; margin-bottom: 84px">
                    Are you sure do you want to add the tribe
                    <strong>{{ values.name }}</strong> to the Tribe List?
                  </h6>
                  <b-spinner
                    type="grow"
                    label="Spinning"
                    v-if="adding"
                  ></b-spinner>
                </div>
              </div>
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </div>
  </Layout>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { TribesMetaData, findLabel } from "@/data/tribes.js";
import { findLabelArabic } from "@/data/tribes_ar.js";
import { region } from "@/config/api/region";
import { tribe } from "@/config/api/tribes";
import { country } from "@/config/api/country";
import { climate } from "@/config/api/climate";
import { challenges } from "@/config/api/challenges";
/**
 * Tribe wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  created() {
    this.getRegions();
    this.getCountries();
    this.getClimate();
    this.getChallenge();
  },
  data() {
    return {
      tribeImages: [],
      adding: false,
      title: "Tribe Wizard",
      findLabel: findLabel,
      isLoadingCountry: false,
      itemsCountry: [],
      itemsClimate: [],
      itemsClimateAr: [],
      itemsChallenge: [],
      itemsChallengeAr: [],
      findLabelArabic: findLabelArabic,
      items: [
        {
          text: "Tribes",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      values: {},
      itemsRegion: [],
      itemsRegionAr: [],
      step1: [
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "text",
              name: "name",
              label: "Tribe Name",
              class: "col-sm-6 col-12",
              validation: "required",
              "error-behavior": "live",
            },
            {
              type: "text",
              name: "name_ar",
              label: "Tribe Name(AR)",
              class: "col-sm-6 col-12",
              "error-behavior": "live",
            },
            {
              type: "text",
              name: "title",
              label: "Tribe Title",
              class: "col-sm-6 col-12",
              validation: "required",
              "error-behavior": "live",
            },
            {
              type: "text",
              name: "title_ar",
              label: "Tribe Title(AR)",
              class: "col-sm-6 col-12",
              "error-behavior": "live",
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "textarea",
              name: "primary_description",
              validation: "required",
              label: "Description",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "primary_description_ar",
              label: "Description(AR)",
              class: "col-sm-6 col-12",
            },
          ],
        },

        {
          component: "div",
          class: "row",
          children: [
            {
              type: "select",
              name: "region",
              validation: "required",
              options: [],
              label: "Region",
              class: "col-sm-4 col-12",
              placeholder: "Select a region",
            },
            {
              type: "select",
              name: "region_ar",
              options: [],
              label: "Region(AR)",
              class: "col-sm-4 col-12",
              placeholder: "Select a region(ar)",
            },

            {
              type: "select",
              name: "climate",
              options: [],
              label: "Climate",
              validation: "required",
              class: "col-sm-4 col-12",
              placeholder: "Select a climate",
            },
            {
              type: "select",
              name: "climate_ar",
              options: [],
              label: "Climate(AR)",
              class: "col-sm-4 col-12",
              placeholder: "Select a climate(ar)",
            },
            {
              type: "select",
              name: "challenge",
              options: [],
              label: "Challenge",
              validation: "required",
              class: "col-sm-4 col-12",
              placeholder: "Select a challenge",
            },
            {
              type: "select",
              name: "challenge_ar",
              options: [],
              label: "Challenge(AR)",
              class: "col-sm-4 col-12",
              placeholder: "Select a challenge(ar)",
            },
            {
              type: "select",
              name: "country",
              options: [],
              label: "Country",
              class: "col-sm-4 col-12",
              placeholder: "Select a country",
            },
          ],
        },
        {
          component: "div",
          class: "row",
          children: [
            {
              type: "number",
              name: "rating",
              label: "Rating",
              validation: "required",
              class: "col-sm-4 col-12",
            },
            {
              type: "number",
              name: "no_of_days",
              label: "Number of Days",
              class: "col-sm-4 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "image",
              name: "cover_image",
              label: "Cover Image",
              class: "col-sm-6 col-12",
              validation: "required|mime:image/jpeg,image/png,image/gif",
              uploader: this.uploadCoverImage,
              "@file-removed": this.removeFile,
              disabled: true,
              help: "You can only upload image after filling the Tribe name !",
            },
            {
              type: "file",
              name: "cover_video",
              label: "Cover Video",
              class: "col-sm-6 col-12",
              validation: "mime:video/mp4",
              uploader: this.uploadCoverVideo,
              "@file-removed": this.removeFile,
              disabled: true,
              help: "You can only upload video after filling the Tribe name !",
            },
          ],
        },
      ],
      step2: [
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "home",
              validation: "required",
              label: "Home",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "home_ar",
              label: "Home(AR)",
              class: "col-sm-6 col-12",
            },

            {
              type: "textarea",
              name: "life",
              validation: "required",
              label: "Life",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "life_ar",
              label: "Life(AR)",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "religion",
              validation: "required",
              label: "Religion",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "religion_ar",
              label: "Religion(AR)",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "social_structure",
              validation: "required",
              label: "Social Structure",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "social_structure_ar",
              label: "Social Structure(AR)",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "language",
              validation: "required",
              label: "Language",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "language_ar",
              label: "Language(AR)",
              class: "col-sm-6 col-12",
            },

            {
              type: "textarea",
              name: "traditions",
              validation: "required",
              label: "Traditions",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "traditions_ar",
              label: "Traditions(AR)",
              class: "col-sm-6 col-12",
            },
          ],
        },
        {
          component: "div",
          class: "row mt-3",
          children: [
            {
              type: "textarea",
              name: "celebrations",
              validation: "required",
              label: "Celebrations",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "celebrations_ar",
              label: "Celebrations(AR)",
              class: "col-sm-6 col-12",
            },

            {
              type: "textarea",
              name: "art",
              label: "Art",
              validation: "required",
              class: "col-sm-6 col-12",
            },
            {
              type: "textarea",
              name: "art_ar",
              label: "Art(AR)",
              class: "col-sm-6 col-12",
            },
          ],
        },

        {
          type: "textarea",
          name: "further_knowledge",
          validation: "required",
          label: "Further Knowledge",
        },
        {
          type: "textarea",
          name: "further_knowledge_ar",
          label: "Further Knowledge(AR)",
        },
      ],
      images: [
        {
          type: "group",
          repeatable: true,
          name: "images",
          label: "Add Images",
          addLabel: "+ Image",
          minimum: 4,
          limit: 9,
          children: [
            {
              component: "div",
              class: "row",
              validation: "required",
              children: [
                {
                  type: "image",
                  name: "image_url",
                  class: "col-md-6 col-12",
                  label: "Image",
                  validation: "required|mime:image/jpeg,image/png,image/gif",
                  "@file-removed": this.removeFile,
                  uploader: this.uploadImage,
                },
                {
                  type: "textarea",
                  class: "col-md-6 col-12",
                  label: "Description",
                  validation: "required",
                  name: "description",
                },
                {
                  type: "textarea",
                  class: "col-md-6 col-12",
                  label: "Description(AR)",
                  name: "description_ar",
                },

                {
                  type: "text",
                  class: "col-md-6 col-12",
                  validation: "required",
                  label: "Photo Credits",
                  name: "photo_credits",
                },
              ],
            },
          ],
        },
      ],
      transport: [
        {
          type: "group",
          repeatable: true,
          name: "transport",
          label: "Add Transports",
          addLabel: "+ Transport",
          class: "tribe-images-upload-container",
          children: [
            {
              component: "div",
              class: "row",
              children: [
                {
                  type: "select",
                  options: TribesMetaData.transport,
                  label: "Type",
                  name: "type",
                  class: "col-sm-4 col-12",
                },
                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Title",
                  name: "title",
                },
                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Title(AR)",
                  name: "title_ar",
                },

                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Text",
                  name: "text",
                },

                {
                  type: "text",
                  class: "col-sm-4 col-12",
                  label: "Text(AR)",
                  name: "text_ar",
                },
              ],
            },
          ],
        },
      ],
      marker: { position: { lat: 46.6987458137373, lng: 24.69330214247961 } },
      center: { lat: 46.6987458137373, lng: 24.69330214247961 },
    };
  },
  watch: {
    values(val) {
      if (val.name && val.name.replace(/ /g, "").length) {
        for (let i = 0; i < this.step1[4].children.length; i++) {
          this.step1[4].children[i].disabled = false;
        }
      } else {
        for (let i = 0; i < this.step1[4].children.length; i++) {
          this.step1[4].children[i].disabled = true;
        }
      }
    },
  },
  methods: {
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
    uploadImageData(data) {
      console.log(data);
    },
    async uploadCoverImage(img, progress) {
      if (this.values.name) {
        const form = new FormData();
        form.append("file", img);
        form.append("fileType", img.type.split("/")[1]);
        form.append("name", this.values.name);
        const api = tribe.uploadMultimedia;
        api.data = form;
        console.log(api);
        const result = await this.fileUploadAPI(api, progress).then((res) => {
          return res.data;
        });
        return result;
      } else {
        console.log("error");
      }
    },
    async uploadCoverVideo(video, progress) {
      console.log(progress);
      const form = new FormData();
      form.append("file", video);
      form.append("fileType", video.type.split("/")[1]);
      form.append("name", this.values.name);
      const api = tribe.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        return res.data;
      });
      return await result;
    },
    async uploadImage(img, progress) {
      const form = new FormData();
      form.append("file", img);
      form.append("fileType", img.type.split("/")[1]);
      form.append("name", this.values.name);
      const api = tribe.uploadMultimedia;
      api.data = form;
      const result = await await this.fileUploadAPI(api, progress).then(
        (res) => {
          return res.data;
        }
      );
      return await result;
    },
    submitTribeForm() {
      this.adding = true;
      let data = { ...this.values };
      data.cover_image = this.getURLFromData(data.cover_image);
      data.cover_video = this.getURLFromData(data.cover_video);
      data.images = this.getImageData(data.images);
      data.latitude = this.marker.position.lat;
      data.longitude = this.marker.position.lng;
      const api = tribe.postTribeService;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.tribesList();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.adding = false;
        });
    },
    getURLFromData(data) {
      return (data && data.results && data.results[0].data) || "";
    },
    getImageData(data) {
      return data.map((item) => {
        item.image_url = this.getURLFromData(item.image_url);
        return item;
      });
    },
    tribesList() {
      this.$bvToast.toast("Tribe added successfully.", {
        title: "Tribe added",
        variant: "success",
        solid: true,
      });
      this.$router.push({ name: "viewTribes" });
    },
    removeFile() {
      //If there is an api for removing file use that
      console.log("File removed!");
    },

    validateStep1() {
      this.$refs["firstStep"].showErrors();
      if (!(this.marker.position.lng && this.marker.position.lat)) {
        return false;
      }

      return this.$refs["firstStep"].isValid;
    },
    validateStep2() {
      this.$refs["SecondStep"].showErrors();
      this.$refs["ThirdStep"].showErrors();
      this.$refs["FourthStep"].showErrors();
      console.log("====================================");
      console.log(this.values);
      console.log("====================================");

      const imageGroupValid = this.values?.images?.every((image) => {
        // Check if description and photo_credits fields are not empty
        return image?.description !== "" && image?.photo_credits !== "";
      });
      return (
        this.$refs["SecondStep"].isValid &&
        this.$refs["ThirdStep"].isValid &&
        this.$refs["FourthStep"].isValid &&
        imageGroupValid === true &&
        this.values?.images !== null
      );
    },
    tabChanged() {
      window.scrollTo(0, 0);
    },
    async getCountries() {
      this.isLoadingCountry = true;
      const api = country.getAll;
      api.params = {
        page: 1,
        limit: 1000,
        // locale: this.selectedLocale,
        // show_on_app: this.selectedOnApp,
      };
      await this.generateAPI(api)
        .then((res) => {
          // <-----------Update eng and arabic dropdown values for region---------->
          this.itemsCountry = res.data.data.map((item) => ({
            label: item.name,
            value: item._id,
          }));

          const countryField = this.step1[2].children.find(
            (child) => child.name === "country"
          );
          if (countryField) {
            countryField.options = this.itemsCountry;
          }

          // <-----------Update eng and arabic dropdown values for region---------->

          this.rows = res.data.totalCount;
          this.perPage = res.data.data.limit;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoadingCountry = false;
          console.log("Loaded");
        });
    },
    async getRegions() {
      this.isLoading = true;
      const api = region.getRegionForAdmin;
      api.params = {
        page: 1,
        limit: 1000,
        // locale: this.selectedLocale,
        // show_on_app: this.selectedOnApp,
      };
      await this.generateAPI(api)
        .then((res) => {
          // <-----------Update eng and arabic dropdown values for region---------->
          this.itemsRegion = res.data.regions.map((item) => ({
            label: item.name,
            value: item.value,
          }));
          this.itemsRegionAr = res.data.regions.map((item) => ({
            label: item.name_ar,
            value: item.name_ar,
          }));

          const regionField = this.step1[2].children.find(
            (child) => child.name === "region"
          );
          if (regionField) {
            regionField.options = this.itemsRegion;
          }
          const regionFieldAr = this.step1[2].children.find(
            (child) => child.name === "region_ar"
          );
          if (regionFieldAr) {
            regionFieldAr.options = this.itemsRegionAr;
          }

          // <-----------Update eng and arabic dropdown values for region---------->

          this.rows = res.data.total_no_of_documents;
          this.perPage = res.data.data.limit;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },
    async getClimate() {
      const api = climate.getClimateForAdmin;
      api.params = { page: 1, limit: 1000 };
      await this.generateAPI(api)
        // <-----------Update eng and arabic dropdown values for clima---------->
        .then((res) => {
          console.log(this.step1[3].children, "this.step1[3].children");
          this.itemsClimate = res.data.map((item) => ({
            label: item.name,
            value: item.value,
          }));
          this.itemsClimateAr = res.data.map((item) => ({
            label: item.name_ar,
            value: item.value,
          }));
          const climateField = this.step1[2].children.find(
            (child) => child.name === "climate"
          );
          if (climateField) {
            climateField.options = this.itemsClimate;
          }
          const climateFieldAr = this.step1[2].children.find(
            (child) => child.name === "climate_ar"
          );
          if (climateFieldAr) {
            climateFieldAr.options = this.itemsClimateAr;
          }
        })
        // <-----------Update eng and arabic dropdown values for region---------->
        .catch((err) => {
          console.log(err);
        });
    },
    async getChallenge() {
      const api = challenges.getChallenges;
      this.generateAPI(api)
        .then((res) => {
          this.itemsChallenge = res.data.map((item) => ({
            label: item.name,
            value: item.value,
          }));
          this.itemsChallengeAr = res.data.map((item) => ({
            label: item.name_ar,
            value: item.value,
          }));
          const challengeField = this.step1[2].children.find(
            (child) => child.name === "challenge"
          );
          if (challengeField) {
            challengeField.options = this.itemsChallenge;
          }
          const challengeFieldAr = this.step1[2].children.find(
            (child) => child.name === "challenge_ar"
          );
          if (challengeFieldAr) {
            challengeFieldAr.options = this.itemsChallengeAr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.images-min-width {
  width: 180px;
}

.preview-container {
  background-color: #fafafa;
  border-radius: 12px;
  padding: 18px 12px;
}

.tribe-form .formulate-input[data-classification="group"] [data-is-repeatable] {
  border: unset !important;
  box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
}
</style>
