import store from "@/state/store";
import permissions from "./permissions";
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
      permissions: permissions.home,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  {
    path: "/vendor/dashboard",
    name: "vendorDashboard",
    meta: {
      authRequired: true,
      permissions: permissions.vendorDashboard,
    },
    component: () => import("../views/pages/dashboard/vendor"),
  },
  {
    path: "/vendor/settings",
    name: "vendorSettings",
    meta: {
      authRequired: true,
      permissions: permissions.vendorSettings,
    },
    component: () => import("../views/pages/settings/vendor/editDetails"),
  },
  {
    path: "/users/normal",
    name: "normalUser",
    meta: {
      authRequired: true,
      permissions: permissions.normalUser,
    },
    component: () => import("../views/pages/user/normal"),
  },
  {
    path: "/users/normal/view",
    name: "NormalUsersdetails",
    meta: { authRequired: true, permissions: permissions.NormalUsersdetails },
    component: () => import("@/views/pages/user/normal/details"),
    redirect: "/users/normal/view/overview",
    children: [
      {
        name: "NormalUserOverview",
        path: "overview",
        component: () => import("@/views/pages/user/normal/details/overview"),
        meta: {
          authRequired: true,
          permissions: permissions.NormalUserOverview,
        },
      },
      {
        name: "NormalUserFollowers",
        path: "followers",
        component: () => import("@/views/pages/user/normal/details/followers"),
        meta: {
          authRequired: true,
          permissions: permissions.NormalUserFollowers,
        },
      },
      {
        name: "NormalUserFollowing",
        path: "following",
        component: () => import("@/views/pages/user/normal/details/following"),
        meta: {
          authRequired: true,
          permissions: permissions.NormalUserFollowing,
        },
      },
      {
        name: "NormalUserLikes",
        path: "likes",
        component: () => import("@/views/pages/user/normal/details/likes"),
        meta: { authRequired: true, permissions: permissions.NormalUserLikes },
      },
      {
        name: "NormalUserPosts",
        path: "posts",
        component: () => import("@/views/pages/user/normal/details/posts"),
        meta: { authRequired: true, permissions: permissions.NormalUserPosts },
      },
    ],
  },
  {
    path: "/users/vendor",
    name: "vendorUser",
    meta: { authRequired: true, permissions: permissions.vendorUser },
    component: () => import("../views/pages/user/vendor"),
  },
  {
    path: "/users/guide",
    name: "programGuide",
    meta: { authRequired: true, permissions: permissions.programGuide },
    component: () => import("../views/pages/user/guide"),
  },
  {
    path: "/reports",
    name: "vendorUser",
    meta: { authRequired: true, permissions: permissions.normalUser },
    component: () => import("../views/pages/reports/index"),
  },
  {
    path: "/tribes",
    name: "viewTribes",
    meta: { authRequired: true, permissions: permissions.viewTribes },
    component: () => import("../views/pages/tribes"),
  },
  {
    path: "/add-tribe",
    name: "addTribe",
    meta: { authRequired: true, permissions: permissions.addTribe },
    component: () => import("../views/pages/tribes/add-tribe"),
  },
  {
    path: "/view-tribe",
    name: "viewTribe",
    meta: { authRequired: true, permissions: permissions.viewTribe },
    component: () => import("../views/pages/tribes/view-tribe"),
  },
  {
    path: "/edit-tribe",
    name: "editTribe",
    meta: { authRequired: true, permissions: permissions.editTribe },
    component: () => import("../views/pages/tribes/edit-tribe"),
  },
  {
    path: "/bookings",
    name: "viewBookings",
    meta: { authRequired: true, permissions: permissions.viewBookings },
    component: () => import("../views/pages/booking"),
  },
  {
    path: "/programs",
    name: "viewPrograms",
    meta: { authRequired: true, permissions: permissions.viewPrograms },
    component: () => import("../views/pages/programs"),
  },
  {
    path: "/program/details",
    name: "viewProgramDetails",
    meta: { authRequired: true, permissions: permissions.viewProgramDetails },
    component: () => import("../views/pages/programs/details"),
  },
  {
    path: "/program/add",
    name: "addProgram",
    meta: { authRequired: true, permissions: permissions.addProgram },
    component: () => import("../views/pages/programs/add-program-form"),
  },
  {
    path: "/program/edit",
    name: "editProgram",
    meta: { authRequired: true, permissions: permissions.editProgram },
    component: () => import("../views/pages/programs/edit-program-form"),
  },
  {
    path: "/program/approve",
    name: "approveProgram",
    meta: { authRequired: true, permissions: permissions.approveProgram },
    component: () => import("../views/pages/programs/approve-program-form"),
  },
  {
    path: "/tickets/users",
    name: "UserTickets",
    meta: { authRequired: true },
    component: () => import("@/views/pages/user/normal/details"),
  },
  {
    path: "/tickets/posts",
    name: "PostTickets",
    meta: { authRequired: true },
    component: () => import("@/views/pages/user/normal/details"),
  },

  {
    path: "/not-authorized",
    name: "notAuthorized",
    meta: { authRequired: false },
    component: () => import("@/views/pages/notAuthorized"),
  },

  {
    path: "/vendor/booking",
    name: "vendorBooking",
    meta: { authRequired: true, permissions: permissions.vendorBooking },
    component: () => import("../views/pages/bookingVendor"),
  },
  {
    path: "/support/:type",
    name: "Support",
    meta: { authRequired: true, permissions: permissions.support },
    component: () => import("@/views/pages/support"),
  },
  {
    path: "/moderation/user",
    name: "ModerationUser",
    meta: { authRequired: true, permissions: permissions.moderation },
    component: () => import("@/views/pages/moderation/UserModeration"),
  },
  {
    path: "/moderation/post",
    name: "ModerationPost",
    meta: { authRequired: true, permissions: permissions.moderation },
    component: () => import("@/views/pages/moderation/PostModeration"),
  },
  {
    path: "/discover",
    name: "discover",
    meta: { authRequired: true, permissions: permissions.normalUser },
    component: () => import("@/views/pages/discover/index"),
  },
  {
    path: "/edit_discover",
    name: "edit_discover",
    meta: { authRequired: true, permissions: permissions.moderation },
    component: () => import("../views/pages/experience"),
  },
  {
    path: "/challenges",
    name: "challenges",
    meta: { authRequired: true, permissions: permissions.challenges },
    component: () => import("@/views/pages/challenges/challenges"),
  },
  {
    path: "/edit-challenge/:id",
    name: "editChallenge",
    meta: { authRequired: true, permissions: permissions.editChallenge },
    component: () => import("../views/pages/challenges/edit-challenge"),
  },
  {
    path: "/coupon",
    name: "coupon",
    meta: { authRequired: true, permissions: permissions.coupon },
    component: () => import("@/views/pages/coupon/index"),
  },
  {
    path: "/region",
    name: "region",
    meta: { authRequired: true, permissions: permissions.region },
    component: () => import("@/views/pages/region/index"),
  },
  {
    path: "/country",
    name: "country",
    meta: { authRequired: true, permissions: permissions.country },
    component: () => import("@/views/pages/country/index"),
  },
  {
    path: "/climate",
    name: "climate",
    meta: { authRequired: true, permissions: permissions.climate },
    component: () => import("@/views/pages/climate/index"),
  },
];
