<template>
  <Layout>
    <div class="card px-4 py-5">
      <div v-if="preparingForm" class="text-center">Preparing Form..</div>
      <div v-else>
        <div
          class="text-center"
          v-if="userType === 'admin' && availableVendor.length === 0"
        >
          Sorry currently vendors are not available!
        </div>

        <div v-else>
          <FormulateForm
            class="add-program-form"
            @submit="updateProgram"
            v-model="formValues"
          >
            <div class="d-flex justify-content-end">
              <FormulateInput
                name="is_featured"
                type="checkbox"
                :checked="false"
                class="is-featured mt-5"
                label="Is featured?"
              />
            </div>
            <div class="row">
              <div class="col-md-6">
                <FormulateInput
                  name="name"
                  type="text"
                  label="Program Name*"
                  placeholder="Program name"
                  validation="required"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  name="name_ar"
                  type="text"
                  label="Program Name(AR)"
                  placeholder="Program name(AR)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <FormulateInput
                  v-if="userType === 'admin'"
                  name="vendor_id"
                  :options="availableVendor"
                  type="select"
                  label="Vendor*"
                  placeholder="Choose vendor"
                  validation="required"
                  @change="filterTribes"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  v-if="userType === 'admin'"
                  name="tribe_id"
                  :options="availableTribe"
                  type="select"
                  label="Tribe*"
                  placeholder="Choose The tribe"
                  validation="required"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="description"
                  type="textarea"
                  label="Description*"
                  placeholder="Description"
                  validation="required"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="description_ar"
                  type="textarea"
                  label="Description(AR)"
                  placeholder="Description(AR)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="program_guide"
                  v-model="SelectedGuide"
                  type="select"
                  label="Program Guide"
                  :options="guideList"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="banner"
                  type="image"
                  label="banner*"
                  :uploader="uploadFile"
                  placeholder="Banner"
                  validation="required"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="what_to_carry"
                  type="file"
                  label="What to carry"
                  :uploader="uploadFilePdf"
                  placeholder="What to carry"
                />
              </div>
              <div class="col-sm-12 col-md-6">
                <FormulateInput
                  name="what_to_carry_ar"
                  type="file"
                  label="What to carry (AR)"
                  :uploader="uploadFilePdf"
                  placeholder="What to carry(AR)"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="row">
                  <div class="col-6">
                    <FormulateInput
                      name="min_people"
                      type="number"
                      label="Min people*"
                      placeholder="Min people"
                      validation="required"
                      min="1"
                      max="50"
                    />
                    <p class="text-danger" id="program-min-error">
                      Minimum people must be less than maximum people
                    </p>
                  </div>
                  <div class="col-6">
                    <FormulateInput
                      name="max_people"
                      type="number"
                      label="Max people*"
                      placeholder="Max people"
                      validation="required"
                      min="1"
                      max="50"
                    />
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-3" v-if="userType === 'admin'">
                <FormulateInput
                  name="vat"
                  label="VAT (%)"
                  placeholder="Vat"
                  max="100"
                  min="0"
                  type="text"
                  pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                  inputmode="numeric"
                  validation="required|between:-1,100"
                  @keyup="calculateTotal"
                />
              </div>
              <div class="col-sm-6 col-md-3" v-if="userType === 'admin'">
                <FormulateInput
                  name="no_of_days"
                  label="Number Of Days*"
                  placeholder="Number Of Days"
                  min="0"
                  value="0"
                  type="text"
                  inputmode="numeric"
                  validation="required"
                  @keyup="calculateTotal"
                />
              </div>
              <div class="col-sm-6 col-md-3">
                <FormulateInput
                  name="preparation_time"
                  label="Preparation time (in days)"
                  placeholder="Days needed to prepare for the trip"
                  min="0.0"
                  type="text"
                  inputmode="numeric"
                />
              </div>
            </div>

            <FormulateInput
              name="is_package"
              type="checkbox"
              :value="false"
              class="package-included mt-5"
              label="Package included"
              @change="packageIncludedAction"
            />
            <FormulateInput
              type="group"
              class="packages"
              :repeatable="false"
              label="Packages"
              name="packages"
              :minimum="minimum_packages"
              #default="{ index }"
            >
              <div v-if="is_package">
                <div class="row">
                  <div class="col-md-3">
                    <FormulateInput
                      name="no_of_people"
                      type="number"
                      label="No. people*"
                      placeholder="Number of people"
                      validation="required"
                      :value="index + 1"
                      disabled
                    />
                  </div>

                  <div class="col-md-3">
                    <FormulateInput
                      name="cost"
                      label="cost*"
                      placeholder="Cost"
                      validation="required"
                      value="0.0"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                      @keyup="calculateTotal"
                    />
                  </div>

                  <div class="col-md-3">
                    <FormulateInput
                      name="markup_percentage"
                      label="Markup Percentage*"
                      max="100"
                      min="0.0"
                      value="0.0"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                      v-if="userType === 'admin'"
                      validation="required"
                      placeholder="Markup percentage"
                      @keyup="calculateTotal"
                    />
                  </div>
                  <div class="col-md-3">
                    <FormulateInput
                      name="total_price"
                      value="0.0"
                      label="Total Price (Including VAT)*"
                      placeholder="Total price"
                      validation="required"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                      v-if="userType === 'admin'"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-md-3">
                    <FormulateInput
                      name="no_of_people"
                      type="number"
                      label="No. people*"
                      placeholder="Number of people"
                      validation="required"
                      value="1"
                      disabled
                    />
                  </div>

                  <div class="col-md-3">
                    <FormulateInput
                      name="cost"
                      label="cost*"
                      placeholder="Cost"
                      validation="required"
                      value="0.0"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                    />
                  </div>

                  <div class="col-md-3">
                    <FormulateInput
                      name="markup_percentage"
                      label="Markup Percentage*"
                      max="100"
                      min="0.0"
                      value="0.0"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                      v-if="userType === 'admin'"
                      validation="required"
                      placeholder="Markup percentage"
                    />
                  </div>
                  <div class="col-md-3">
                    <FormulateInput
                      name="total_price"
                      value="0.0"
                      label="Total Price*"
                      placeholder="Total price"
                      validation="required"
                      type="text"
                      pattern="[+-]?((\d+\.?\d*)|(\.\d+))"
                      inputmode="numeric"
                      v-if="userType === 'admin'"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </FormulateInput>
            <div
              class="d-flex justify-content-end"
              style="width: 100%"
              v-if="userType === 'admin'"
            >
              <button
                type="button"
                @click="calculateTotal"
                class="date-gen-btn"
              >
                Calculate Total
              </button>
            </div>

            <div>
              <FormulateInput
                type="group"
                class="policies my-5"
                label="Policy"
                name="policy"
              >
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="covid_requirements"
                      type="textarea"
                      label="Vaccine Requirements*"
                      placeholder="Vaccine Requirements"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="covid_requirements_ar"
                      type="textarea"
                      label="Vaccine Requirements(AR)"
                      placeholder="Vaccine Requirements(AR)"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="cancellation_policy"
                      type="textarea"
                      label="Cancellation Policy*"
                      placeholder="Cancellation policy"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="cancellation_policy_ar"
                      type="textarea"
                      label="Cancellation Policy(AR)"
                      placeholder="Cancellation policy(AR)"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="included"
                      type="textarea"
                      label="Included*"
                      placeholder="Included"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="included_ar"
                      type="textarea"
                      label="Included(AR)"
                      placeholder="Included(AR)"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="excluded"
                      type="textarea"
                      label="Excluded *"
                      placeholder="Excluded"
                      validation="required"
                    />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <FormulateInput
                      name="excluded_ar"
                      type="textarea"
                      label="Excluded (AR)"
                      placeholder="Excluded (AR)"
                    />
                  </div>
                </div>
              </FormulateInput>
            </div>
            <FormulateInput
              type="group"
              class="details mb-5"
              :repeatable="true"
              label="Itinerary"
              name="details"
            >
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="title"
                    type="text"
                    label="Title*"
                    placeholder="Title"
                    validation="required"
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="title_ar"
                    type="text"
                    label="Title(AR)"
                    placeholder="Title(AR)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-4">
                  <FormulateInput
                    name="day"
                    type="text"
                    label="Day*"
                    placeholder="eg: Day 1"
                    validation="required"
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <FormulateInput
                    name="time"
                    type="text"
                    label="Time Slot*"
                    placeholder="eg: 8:00pm"
                    validation="required"
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <FormulateInput
                    name="sorting"
                    type="number"
                    label="Sorting Number*"
                    placeholder="eg: 1"
                    validation="required"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="description"
                    type="textarea"
                    label="Description*"
                    placeholder="Description"
                    validation="required"
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="description_ar"
                    type="textarea"
                    label="Description(AR)"
                    placeholder="Description(AR)"
                  />
                </div>
                <div class="col-sm-12 col-md-6">
                  <FormulateInput
                    name="image"
                    type="image"
                    label="Image*"
                    :uploader="uploadFile"
                    placeholder="Image"
                    validation="required"
                  />
                </div>
              </div>
            </FormulateInput>

            <div class="d-flex justify-content-end" style="width: 100%">
              <button
                type="button"
                @click="$bvModal.show('date-gen')"
                class="date-gen-btn"
              >
                Generate Date
              </button>
              <dateGen
                v-on:addDateGenerated="addDateGenerated"
                v-on:removeDates="removeDates"
              />
            </div>
            <FormulateInput
              type="group"
              :repeatable="true"
              label="Available date"
              name="available_dates"
              class="program-date"
              style="margin-top: -38px"
            >
              <FormulateInput
                name="date"
                type="date"
                label="Date*"
                :value="{}"
                placeholder="Available dates"
                :validation="`required|after:${previousDay}`"
              />
            </FormulateInput>

            <FormulateInput
              name="all_dates_available"
              type="checkbox"
              :value="false"
              class="all-date-available"
              label="All dates available"
            />

            <FormulateInput
              class="float-right"
              type="submit"
              :disabled="updatingProgram"
              :label="
                updatingProgram ? 'Updating Program...' : 'Update Program'
              "
            />
          </FormulateForm>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { program } from "@/config/api/programs";
import dateGen from "@/views/pages/programs/date-gen.vue";
import { programGetters } from "@/state/helpers";
import compress from "@/utils/compress";
import axios from "axios";
export default {
  data() {
    return {
      guideList: [],
      availableVendor: [],
      availableTribe: [],
      formValues: {},
      preparingForm: false,
      updatingProgram: false,
      is_package: false,
      programSelected: null,
      minimum_packages: 1,
      min_people: 1,
      SelectedGuide: "",
    };
  },
  created() {
    this.getGuideList();
    this.getAllVendorAvailableForProgram();
    if (!this.selectedProgram()) {
      this.$router.go(-1);
    }
  },
  methods: {
    ...programGetters,
    getGuideList() {
      const api = program.getGuideList;
      this.generateAPI(api)
        .then((res) => {
          this.guideList = res.data.data.map((item) => {
            return {
              value: item._id,
              label: item.name,
            };
          });
        })
        .finally(() => {
          this.guideList.push({ value: "", label: "No Guide" });
          console.log(this.guideList);
        });
    },
    minPeopleErrorShow() {
      document.getElementById("program-min-error").style.display = "block";
    },
    minPeopleErrorHide() {
      document.getElementById("program-min-error").style.display = "none";
    },
    hasDuplicateDates(array) {
      const seen = new Set();
      for (const item of array) {
        if (seen.has(item.date)) {
          return true; // Duplicate found
        }
        seen.add(item.date);
      }
      return false; // No duplicates
    },

    updateProgram(data) {
      if (parseInt(data.min_people) > parseInt(data.max_people)) {
        return;
      }
      if (this.hasDuplicateDates(data.available_dates)) {
        this.$bvToast.toast("There should not be any duplicate dates!", {
          title: "Program",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.calculateTotal();
      this.updatingProgram = true;

      const api =
        this.userType === "admin"
          ? program.updateProgramAdmin
          : program.updateProgram;

      // api.data = this.formatData(data);
      // this.generateAPI(api)
      // const apiUrl =
      //   this.userType === "admin"
      //     ? "admin/program/updateForAdmin"
      //     : "vendor/program/updateForVendor";

      const formattedData = this.formatData(data);
      const bearerToken = localStorage.getItem("token");

      axios
        .post(process.env.VUE_APP_BASE_URL + api?.url, formattedData, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        })

        .then(() => {
          this.$bvToast.toast("Program updated successfully", {
            title: "Program",
            variant: "success",
            solid: true,
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$bvToast.toast("Program is not updated!", {
            title: "Program",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updatingProgram = false;
        });
    },
    formatData(data) {
      console.log(data.what_to_carry, "data.what_to_carry");
      // <------------------To provide empty string if no what_to_carry file is uploaded for eng and ar------------------>
      if (
        data.what_to_carry?.length === 0 ||
        JSON.stringify(data.what_to_carry) === JSON.stringify([{}])
      ) {
        data.what_to_carry = "";
      }
      if (
        data.what_to_carry_ar?.length === 0 ||
        JSON.stringify(data.what_to_carry_ar) === JSON.stringify([{}])
      ) {
        data.what_to_carry_ar = "";
      }
      // <------------------To provide empty string if no what_to_carry file is uploaded for eng and ar------------------>

      if (data.what_to_carry) {
        if (data.what_to_carry[0]?.url) {
          data.what_to_carry = data?.what_to_carry[0]?.url;
        }
        if (data.what_to_carry[0]?.link) {
          data.what_to_carry = data.what_to_carry[0].link;
        }
      }

      if (data.what_to_carry_ar) {
        if (data.what_to_carry_ar[0]?.url) {
          data.what_to_carry_ar = data?.what_to_carry_ar[0]?.url;
        }
        if (data.what_to_carry_ar[0]?.link) {
          data.what_to_carry_ar = data.what_to_carry_ar[0].link;
        }
      }

      // data.what_to_carry_ar = this.file_link_ar;
      data.type = this.userType === "admin" ? "admin" : "vendor";
      data.programId = this.programSelected._id;
      data.banner = data.banner.length ? data.banner[0].link : "";
      data.all_dates_available = data.all_dates_available || false;
      data.more_details = {
        min_people: data.min_people,
        max_people: data.max_people,
        included: data.policy[0].included,
        included_ar: data.policy[0].included_ar,
        excluded: data.policy[0].excluded,
        excluded_ar: data.policy[0].excluded_ar,
      };
      if (this.SelectedGuide != "") {
        data.program_guide = this.SelectedGuide;
      } else {
        data.program_guide = null;
      }
      if (data.available_dates) {
        data.available_dates = data.available_dates.map((item) => {
          return item.date;
        });
      } else {
        data.available_dates = [];
      }

      data.cancellation_policy = data.policy[0].cancellation_policy;
      data.cancellation_policy_ar = data.policy[0].cancellation_policy_ar;

      data.covid_requirements = data.policy[0].covid_requirements || "";
      data.covid_requirements_ar = data.policy[0].covid_requirements_ar || "";

      delete data.policy;
      data.is_package = data.is_package ? true : false;
      console.log("Details", data.details);
      data.details = data.details.map((item) => {
        return {
          title: item.title,
          title_ar: item.title_ar,
          description: item.description,
          description_ar: item.description_ar,
          time: item.time,
          sorting: item.sorting,
          day: item.day,
          image: item.image.results.length
            ? item.image.results[0].link
              ? item.image.results[0].link
              : item.image.results[0].url
            : "",
        };
      });
      return data;
    },
    async uploadFile(file, progress) {
      const form = new FormData();
      const compressed_file = await compress([file]);
      form.append("file", compressed_file);
      form.append("type", this.userType === "admin" ? "admin" : "vendor");
      const api = program.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        return res.data;
      });
      return result;
    },

    async uploadFilePdf(file, progress) {
      const form = new FormData();
      form.append("file", file);
      form.append("type", this.userType === "admin" ? "admin" : "vendor");
      const api = program.uploadMultimedia;
      api.data = form;
      const result = await this.fileUploadAPI(api, progress).then((res) => {
        return res.data;
      });
      return result;
    },

    async getAllVendorAvailableForProgram() {
      this.preparingForm = true;

      const api = program.getAvailableVendor;
      return await this.generateAPI(api)
        .then((res) => {
          this.availableVendor = res.data.vendors.map((item) => {
            return {
              label: item.company_name,
              value: item._id,
              tribes: item.assigned_tribe,
            };
          });
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.preparingForm = false;
          this.programSelected = JSON.parse(
            JSON.stringify(this.selectedProgram())
          );
          this.SelectedGuide =
            (this.programSelected && this.programSelected.program_guide?._id) ||
            "";
        });
    },
    addDateGenerated(data) {
      if (!this.formValues) {
        this.formValues = {};
      }

      if (!Array.isArray(this.formValues.available_dates)) {
        this.formValues.available_dates = [];
      }
      this.$bvModal.hide("date-gen");

      // const date_already_available = this.formValues.available_dates.map(
      //   (item) => item.date
      // );
      const date_already_available = Array.isArray(
        this.formValues.available_dates
      )
        ? this.formValues.available_dates.map((item) => item.date)
        : [];

      for (let i = 0; i < data.length; i++) {
        if (!date_already_available.includes(data[i])) {
          this.formValues.available_dates.push({
            date: data[i],
            _id: i, // Ensure this is unique
          });
        }
      }
    },
    removeDates(data) {
      this.$bvModal.hide("date-gen");

      const date_already_available = this.formValues.available_dates.map(
        (item) => item.date
      );

      for (let i = 0; i < data.length; i++) {
        if (date_already_available.includes(data[i])) {
          this.formValues.available_dates.pop(data[i]);
        }
      }
    },

    calculateTotal() {
      for (let i = 0; i < this.formValues.packages.length; i++) {
        this.formValues.packages[i].total_price = this.total(
          this.formValues.packages[i]
        ).toFixed(2);
      }
    },
    total(item) {
      const tax = this.formValues.vat || 0;
      const total_price =
        parseFloat(item.cost || 0) * parseFloat(item.no_of_people || 0);
      let subTotal = total_price + total_price * (item.markup_percentage / 100);
      return subTotal + (subTotal * tax) / 100;
    },
    filterTribes() {
      this.availableTribe = this.availableVendor
        .find((item) => item.value == this.formValues.vendor_id)
        .tribes.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
    },
    packageIncludedAction(val) {
      this.is_package = val.target.checked;
      if (this.is_package) {
        if (this.formValues.max_people <= 50) {
          this.min_people = this.formValues.min_people;
          this.minimum_packages = this.formValues.max_people || 0;
          for (let i = 0; i < this.formValues.packages.length; i++) {
            this.formValues.packages[i].no_of_people = i + 1;
          }
        } else {
          this.$bvToast.toast("Max people must beteen 1-50", {
            title: "Max People",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        this.minimum_packages = 1;
        this.min_people = 1;
        this.formValues.packages = [this.formValues.packages[0]];
      }
    },
  },
  components: {
    Layout,
    dateGen,
  },
  computed: {
    userType() {
      return this.$store.getters["auth/userType"];
    },

    previousDay() {
      // Get yesterday's date in "YYYY-MM-DD" format
      const date = new Date();
      date.setDate(date.getDate() - 1); // Subtract one day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    formValues(val) {
      if (parseInt(val.min_people) > parseInt(val.max_people)) {
        this.minPeopleErrorShow();
      } else {
        this.minPeopleErrorHide();
      }
      if (
        (val.max_people && val.max_people !== this.minimum_packages) ||
        (val.min_people && val.min_people !== this.min_people)
      ) {
        if (val.is_package && val.max_people) {
          if (val.max_people <= 50) {
            this.minimum_packages = val.max_people;
            this.min_people = val.min_people;
            for (let i = 0; i < this.formValues.packages.length; i++) {
              this.formValues.packages[i].no_of_people = i + 1;
            }
            this.formValues.packages = this.formValues.packages.slice(
              0,
              this.minimum_packages
            );
          } else {
            this.$bvToast.toast("Max people must between 1-50", {
              title: "Max People",
              variant: "danger",
              solid: true,
            });
          }
        }
      }
    },
    programSelected(val) {
      const data = { ...val };
      this.minimum_packages;

      data.banner = [
        {
          url: val.banner,
        },
      ];
      if (data?.what_to_carry) {
        data.what_to_carry = [
          {
            url: val.what_to_carry,
          },
        ];
      }
      if (data?.what_to_carry_ar) {
        data.what_to_carry_ar = [
          {
            url: val.what_to_carry_ar,
          },
        ];
      }

      this.is_package = val.is_package;

      data.max_people = val.more_details.max_people;
      this.minimum_packages = data.max_people;
      data.min_people = val.more_details.min_people;
      this.min_people = data.min_people;
      const dates = [];
      if (data.available_dates) {
        data.available_dates.map((item) => {
          dates.push({
            date: item,
          });
        });
        data.available_dates = dates;
      }

      data["details"] = data["details"].map((item) => {
        item.image = [
          {
            url: item.image,
          },
        ];
        return item;
      });

      data["policy"] = [
        {
          covid_requirements: val.covid_requirements || "",
          covid_requirements_ar: val.covid_requirements_ar || "",
          cancellation_policy: val.cancellation_policy || "",
          cancellation_policy_ar: val.cancellation_policy_ar || "",
          included: val.more_details.included || "",
          included_ar: val.more_details.included_ar || "",
          excluded: val.more_details.excluded || "",
          excluded_ar: val.more_details.excluded_ar || "",
        },
      ];

      if (data.is_package && data.packages.length < this.minimum_packages) {
        let temp = [];
        for (let i = 0; i < this.minimum_packages - data.packages.length; i++) {
          temp.push({});
        }
        data.packages.forEach((element) => {
          temp.push(element);
        });
        data.packages = temp;
      } else {
        this.min_people = 1;
        this.minimum_packages = 1;
      }

      this.formValues.vendor_id = val.vendor_id;
      this.filterTribes();
      this.formValues = data;
    },
  },
};
</script>

<style>
.add-program-form {
  padding: 32px;
}

.program-date .formulate-input-grouping {
  display: flex;
  flex-wrap: wrap;
}

.add-program-form
  .formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable {
  border: unset;
}

.add-program-form
  .formulate-input[data-classification="group"]
  [data-is-repeatable]
  .formulate-input-group-repeatable-remove {
  top: 62px;
}

.add-program-form .policies .formulate-input-group {
  padding: 1em;
  border: 1px solid #efefef;
  border-radius: 0.3em;
}

.formulate-input-element .formulate-input-element--button {
  text-align: end;
}

.date-gen-btn {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fa7da9;
  font-size: 12px;
  opacity: 0.7;
}

.date-gen-btn:hover {
  opacity: 1;
}

.all-date-available .formulate-input-element--checkbox {
  margin-top: 8px !important;
}

.package-included {
  margin-bottom: 4px;
}

.package-included .formulate-input-element--checkbox {
  margin-top: 8px !important;
}
</style>
