<template>
  <div class="booking-details-wrapper">
    <div class="booking-details">
      <img src="@/assets/images/booking/info.png" alt="" srcset="" />

      <div class="details">
        <div class="row">
          <div class="col-5">
            <strong> Program</strong>
          </div>
          <div class="col-7">
            {{ data.program_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong> Vendor</strong>
          </div>
          <div class="col-7">
            {{ data.vendor.company_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong>Vendor Phone</strong>
          </div>
          <div class="col-7">
            {{ data.vendor.contact_number }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong> Vendor Email</strong>
          </div>
          <div class="col-7">
            {{ data.vendor.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong>Booking Date</strong>
          </div>
          <div class="col-7">
            {{ moment(data.booking_date).format("LL") }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong>Reserved on</strong>
          </div>
          <div class="col-7">
            {{ moment(data.reserved_on).format("LL") }}
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <strong>Booked by</strong>
          </div>
          <div class="col-7">
            {{ data.booked_by.name }} ( {{ data.booked_by.email }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.booking-details-wrapper {
  background-color: #f1f5f7;
  color: black;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  margin-bottom: 8px;
  position: relative;
}

.booking-details {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.booking-details .details {
  font-size: 14px;
  padding: 4px;
}

.booking-details img {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
  height: 120px;
  opacity: 0.1;
}

.details {
  width: 100%;
}
</style>
